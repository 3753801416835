import React, { Component, FormEvent } from "react"
import "./style.scss"
import FormButton from "@components/_atoms/button_form_input_submit"
import * as progress from "@services/progress"
import ListItem from "@components/_atoms/custom_list_item"
import { Headline2A } from "@components/_atoms/Text"
import * as beneficiaryToken from "@services/beneficiaryToken"
import SecurityModal from "@components/_molecules/modal_security"
import Debug from "debug"
import BackButton from "@components/back_button"

const debug = Debug("lae:" + __filename)
const isClient = typeof window !== "undefined"

class GettingStarted extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ask: false,
    }
  }

  componentDidMount = () => {
    beneficiaryToken.setToken()

    if (!this.props.setNextStep) {
      //Checks if user has already completed this part. If they have, they will be redirected
      let regPosition = progress.getNext()
      if (regPosition !== "register" && isClient) {
        window.location.href = `/pledge/register/${regPosition}`
      }
    }
  }

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (this.props.setNextStep) {
      this.props.setNextStep("personal-information")
      this.props.navigate(this.props.nextForm)
    } else {
      progress.updateProgress()
      window.location.href = `/pledge/register/${progress.getNext()}`
    }
  }

  handleOK = () => {
    this.setState({ ask: false })
  }
  modal = () => {
    this.setState({ ask: true })
  }
  render() {
    return (
      <div className="getting-started">
        {this.props.navigate && this.props.data && (
          <BackButton backLinkNative={this.props.data.backLinkNative} />
        )}
        {this.state.ask && (
          <SecurityModal
            data={{
              title: "More info",
              copy: "We take the security of your personal information very seriously. As a registered investment adviser, we are required by federal law (U.S. Patriot Act) to collect this information. Your SSN is encrypted and only used for regulatory identification purposes.",
            }}
            onConfirm={this.handleOK}
          />
        )}
        <Headline2A className="heading-spacing">Let's Get Started</Headline2A>
        <p className="sub-heading">
          We’ll get your child’s UTMA/UGMA account setup so they can learn and
          invest safely. We just need a few pieces of information.
        </p>

        <ListItem
          style="list-item-heading number-one"
          heading="Your info"
          text="Simple things like address & DOB to make you the account holder."
        />
        <ListItem
          style="list-item-heading number-two"
          heading="Child info"
          text="Child info Info such as DOB & SSN to make them the beneficiary."
        />
        <ListItem
          style="list-item-heading number-three"
          heading="Their first investment"
          text="Give your child a headstart with a donation to their new
          account."
        />
        <div style={{ width: `100%`, height: `3rem` }}></div>
        <form onSubmit={this.handleSubmit}>
          <FormButton text="Get Started" />
        </form>
        <div className="info-link">
          <span className="link" onClick={this.modal}>
            Why do we need this?
          </span>
        </div>
      </div>
    )
  }
}

export default GettingStarted
