import React from "react"

import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import GettingStarted from "@components/getting_started"

export default function GettingStartedPage() {
  const { gettingStarted } = data
  return (
    <Layout type="gray" pageClass="pledge">
      <SEO title={gettingStarted.title} />
      <BackButton
        data={gettingStarted.backLink}
        backLinkNative={gettingStarted.backLinkNative}
      />
      <GettingStarted />
    </Layout>
  )
}
