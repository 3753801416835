import React from "react"
import "./style.scss"

const ListItem = ({ style, heading, text }) => {
  return (
    <div>
      <p className={style}>{heading}</p>
      <p className="text">{text}</p>
    </div>
  )
}

export default ListItem
